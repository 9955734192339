import { toast } from 'react-toastify';
import { AllErrors } from './errorService';

const API_URL = `${process.env.REACT_APP_URL}/api/v1/`;

const successMessage = (message) => {
  return new Promise((resolve) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 800,
      onClose: () => resolve(),
    });
  });
};

const errorMessage = (message) => {
  return new Promise((resolve) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 2000,
      onClose: () => resolve(),
    });
  });
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleResponse = async (response) => {
  const data = await response.json();

  if (!response.ok) {
    const errorCode = data?.detail?.errorCode;
    const detailMessage = data?.detail?.detailMessage || 'Something went wrong';

    if (errorCode && AllErrors[errorCode]) {
      throw new Error(`${errorCode} message: ${detailMessage}`);
    } else {
      throw new Error(detailMessage);
    }
  }

  return data;
};

const createRequestOptions = async (method, data = null, useAuth = true) => {
  const token = sessionStorage.getItem('token');

  const headers = new Headers();
  if (method !== 'GET') {
    headers.append('Content-Type', 'application/json');
  }

  if (useAuth && token) {
    headers.append('Authorization', token);
  }

  const options = {
    method,
    headers,
    redirect: 'follow',
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  return options;
};

export const RefreshTokenApi = async () => {
  try {
    const refreshToken = sessionStorage.getItem('refreshtoken');
    if (!refreshToken) {
      errorMessage('Session expired');
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    } else {
      const options = await createRequestOptions('POST', { refresh_token: refreshToken }, false);
      const response = await fetch(`${API_URL}refresh_token`, options);
      return await handleResponse(response);
    }
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};

const fetchWithAuth = async (url, options, retry = false) => {
  const excludedUrls = [
    'https://prod-fleet-management-api.dacio.ai/api/v1/token',
    'https://staging-fleet-management-api.dacio.ai/api/v1/token',
  ];

  // If the URL is one of the excluded URLs, skip further processing
  if (excludedUrls.includes(url)) {
    return await fetch(url, options);
  }
  let response = await fetch(url, options);

  if ((!response.ok && response.status === 401) || response.status === 403) {
    const newToken = await RefreshTokenApi();
    if (newToken) {
      sessionStorage.setItem('token', newToken?.data?.access_token);
      options.headers.set('Authorization', `Bearer ${newToken?.data?.access_token}`);
      response = await fetch(url, options);
      window.location.reload();
    } else {
      // If refresh token is also invalid or expired, log the user out and redirect to login
      errorMessage('Session expired');
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
  }

  return response;
};

const apiRequestHandler = async (
  url,
  options,
  successMessageText = null,
  shouldThrowError = true,
) => {
  try {
    const response = await fetchWithAuth(url, options, false);
    const result = await handleResponse(response);
    if (successMessageText) {
      await successMessage(successMessageText);
    }
    return result;
  } catch (error) {
    console.error('Fetch error:', error);
    if (shouldThrowError) {
      await errorMessage(error.message || 'Something went wrong');
      throw error;
    } else {
      throw error;
    }
  }
};

const Api_Service = {
  get: async (endpoint, params = null, shouldThrowError = true) => {
    const url = params ? `${API_URL}${endpoint}${params}` : `${API_URL}${endpoint}`;
    const options = await createRequestOptions('GET', null, true);
    return apiRequestHandler(url, options, null, shouldThrowError);
  },

  post: async (endpoint, data, id = null, shouldThrowError = true) => {
    const url = id ? `${API_URL}${endpoint}/${id}` : `${API_URL}${endpoint}`;
    const options = await createRequestOptions('POST', data, true);

    return apiRequestHandler(url, options, 'Operation successful', shouldThrowError);
  },

  postWithoutMessage: async (endpoint, data, params = null, shouldThrowError = true) => {
    const url = params ? `${API_URL}${endpoint}${params}` : `${API_URL}${endpoint}`;
    const options = await createRequestOptions('POST', data, true);

    return apiRequestHandler(url, options, null, shouldThrowError);
  },

  authPost: async (endpoint, data = null, params = null, shouldThrowError = true) => {
    const url = params ? `${API_URL}${endpoint}${params}` : `${API_URL}${endpoint}`;
    const options = await createRequestOptions('POST', data, true);

    return apiRequestHandler(url, options, 'Operation successful', shouldThrowError);
  },

  put: async (endpoint, data, shouldThrowError = true) => {
    const url = `${API_URL}${endpoint}`;
    const options = await createRequestOptions('PUT', data);

    return apiRequestHandler(url, options, 'Operation successful', shouldThrowError);
  },

  delete: async (endpoint, params = null, shouldThrowError = true) => {
    const url = params ? `${API_URL}${endpoint}${params}` : `${API_URL}${endpoint}`;
    const options = await createRequestOptions('DELETE');

    return apiRequestHandler(url, options, 'Operation successful', shouldThrowError);
  },

  uploadImage: async (fileInput, shouldThrowError = true) => {
    const formData = new FormData();
    formData.append('file', fileInput);

    const options = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    };

    const url = `${API_URL}upload_image`;
    return apiRequestHandler(url, options, 'Image uploaded successfully', shouldThrowError);
  },
};

export default Api_Service;
